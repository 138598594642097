import service from '@/services/SIRgetDocuments';

export default {
  namespaced: true,

  state: {
    data: [],
    loading: false,
  },

  mutations: {
    /**
     * Establece un nuevo valor para la variable de estado data.
     */
    SET_DATA(state, data) {
      const docs = JSON.parse(JSON.stringify(data));

      if (docs !== undefined) {
        // Permite trabajar con documentos en el ambiente local de un desarrollador frontend
        // Previene problema de CORS cuando el desarrollador frontend trabaja en su ambiente local.
        if (process.env.NODE_ENV === 'development') {
          for (let i = 0; i < docs.length; i += 1) {
            if (docs[i].isFile) {
              const sharedPublicURL = docs[i].sharedPublicURL.replace(docs[i].sharedPublicURL.substring(0, docs[i].sharedPublicURL.lastIndexOf('/')), process.env.VUE_APP_DOMAIN);

              docs[i].sharedPublicURL = sharedPublicURL.substring(0, sharedPublicURL.indexOf('?'));

              docs[i].publicThumbailUrl = docs[i].sharedPublicURL;
            } else {
              for (let j = 0; j < docs[i]?.eePropertiesDet?.length; j += 1) {
                const sharedPublicURL = docs[i].eePropertiesDet[j].sharedPublicURL.replace(docs[i].eePropertiesDet[j].sharedPublicURL.substring(0, docs[i].eePropertiesDet[j].sharedPublicURL.lastIndexOf('/')), process.env.VUE_APP_DOMAIN);

                docs[i].eePropertiesDet[j].sharedPublicURL = sharedPublicURL.substring(0, sharedPublicURL.indexOf('?'));

                const publicThumbailUrl = docs[i].eePropertiesDet[j].sharedPublicURL;
                docs[i].eePropertiesDet[j].publicThumbailUrl = publicThumbailUrl;
              }
            }
          }
        }

        docs.forEach((bolsillo) => {
          if (bolsillo.eePropertiesDet !== undefined) {
            const indiceDocumentoPrincipal = bolsillo.eePropertiesDet.findIndex(
              (documentoPrincipal) => documentoPrincipal.isPPAL,
            );

            if (indiceDocumentoPrincipal !== -1) {
              const documento = bolsillo.eePropertiesDet.splice(
                indiceDocumentoPrincipal,
                1,
              );

              bolsillo.eePropertiesDet.unshift(documento[0]);
            }
          }
        });
      }

      state.data = docs;
    },

    /**
     * Establece el valor del estado de una petición REST.
     */
    SET_LOADING(state, data) {
      state.loading = data;
    },
  },

  actions: {
    /**
     * Petición para obtener los documentos de un ID de Sala agrupados por Bolsillo.
     */
    async read({ commit, dispatch, rootGetters }, values) {
      const { roomId } = values;

      const payload = {
        dsSIRgetDocuments: {
          eeDatos: [rootGetters['auth/state']],
          eeSIRgetDocuments: [
            {
              piisal_cod: roomId,
            },
          ],
        },
      };

      commit('SET_LOADING', true);

      let notification;

      await service
        .read(payload)
        .then((response) => {
          const data = response.data.dsSIRgetDocuments;

          if (data.eeEstados[0].Returnid === 0 || data.eeEstados[0].Returnid === 98) {
            if (data?.eeProperties) {
              commit('SET_DATA', data.eeProperties);
            } else {
              commit('SET_DATA', []);
            }
          }
        })
        .catch((error) => {
          if (error.type === undefined) {
            notification = {
              code: undefined,
              message: error.message,
              timestamp: Date.now(),
              type: 'error',
            };
          }

          dispatch('notification/create', notification, {
            root: true,
          });

          throw notification;
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    },
  },
};
