import service from '@/services/SICUDdocumentforinstance';

export default {
  namespaced: true,

  state: {
    loading: false,
  },

  mutations: {
    /**
     * Establece el valor del estado de una petición REST.
     */
    SET_LOADING(state, data) {
      state.loading = data;
    },
  },

  actions: {
    /**
     * Petición para subir un documento a una Sala Integrity.
     */
    async create({ commit, dispatch, rootGetters }, data) {
      const { bolsilloId = 0 } = data;
      const { fileName } = data;
      const { roomId } = data;
      const { base64 } = data;

      const payload = {
        dsSICUDdocumentforinstance: {
          eeDatos: [rootGetters['auth/state']],
          eedocumentforinstance: [
            {
              bol_id: bolsilloId,
              picnamefile: fileName,
              sal_cod: roomId,
              val_file: base64,
            },
          ],
        },
      };

      commit('SET_LOADING', true);

      let notification;

      await service
        .create(payload)
        .then((response) => {
          if (
            response.data.dsSICUDdocumentforinstance.eeEstados[0].Returnid
            !== 0
            && response.data.dsSICUDdocumentforinstance.eeEstados[0].Returnid
            !== 98
          ) {
            notification = {
              code: response.data.dsSICUDdocumentforinstance.eeEstados[0]
                .Returnid,
              message:
                response.data.dsSICUDdocumentforinstance.eeEstados[0].Estado,
              timestamp: Date.now(),
              type: 'error',
            };

            throw notification;
          }
        })
        .catch((error) => {
          if (error.type === undefined) {
            notification = {
              code: undefined,
              message: error.message,
              timestamp: Date.now(),
              type: 'error',
            };
          }

          dispatch('notification/create', notification, {
            root: true,
          });

          throw notification;
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    },

    /**
     * Petición para retirar un documento que reposa en el gestor documental.
     */
    async delete({ dispatch, rootGetters }, data) {
      const { idDMS } = data;
      const { roomId } = data;

      const payload = {
        dsSICUDdocumentforinstance: {
          eeDatos: [rootGetters['auth/state']],
          eedocumentforinstance: [
            {
              picid: idDMS,
              sal_cod: roomId,
            },
          ],
        },
      };

      let notification;

      await service
        .delete(payload)
        .then((response) => {
          if (
            response.data.dsSICUDdocumentforinstance.eeEstados[0].Returnid
            !== 0
            && response.data.dsSICUDdocumentforinstance.eeEstados[0].Returnid
            !== 98
          ) {
            notification = {
              code: response.data.dsSICUDdocumentforinstance.eeEstados[0]
                .Returnid,
              message:
                response.data.dsSICUDdocumentforinstance.eeEstados[0].Estado,
              timestamp: Date.now(),
              type: 'error',
            };

            throw notification;
          }
        })
        .catch((error) => {
          if (error.type === undefined) {
            notification = {
              code: undefined,
              message: error.message,
              timestamp: Date.now(),
              type: 'error',
            };
          }

          dispatch('notification/create', notification, {
            root: true,
          });

          throw notification;
        });
    },

    /**
     * Petición para actualizar el parámetro isPPAL de un bolsillo o un documento.
     */
    async update({ dispatch, rootGetters }, data) {
      const { idDMS } = data;
      const { idIntegrity } = data;
      const { roomId } = data;

      const payload = {
        dsSICUDdocumentforinstance: {
          eeDatos: [rootGetters['auth/state']],
          eedocumentforinstance: [
            {
              bol_id: idIntegrity,
              sal_cod: roomId,
              picid: idDMS,
            },
          ],
        },
      };

      let notification;

      await service
        .update(payload)
        .then((response) => {
          if (
            response.data.dsSICUDdocumentforinstance.eeEstados[0].Returnid
            !== 0
            && response.data.dsSICUDdocumentforinstance.eeEstados[0].Returnid
            !== 98
          ) {
            notification = {
              code: response.data.dsSICUDdocumentforinstance.eeEstados[0]
                .Returnid,
              message:
                response.data.dsSICUDdocumentforinstance.eeEstados[0].Estado,
              timestamp: Date.now(),
              type: 'error',
            };

            throw notification;
          }
        })
        .catch((error) => {
          if (error.type === undefined) {
            notification = {
              code: undefined,
              message: error.message,
              timestamp: Date.now(),
              type: 'error',
            };
          }

          dispatch('notification/create', notification, {
            root: true,
          });

          throw notification;
        });
    },
  },
};
